.upload-section {
    display: flex;
    height: 200px;
    margin-bottom: 10px;
  
  }
  
  .drop-zone {
    margin-bottom: 10px;
    padding: 40px 10px;
    border: 2px dashed #143474;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  
  .image-preview {
    height: 150px;
    width: 150px;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  .preview-image {
    margin-bottom: 100px;
  }
  
  .preview-message {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
  }